import React, { useState } from "react"
import styled from "@emotion/styled"
import { Article, ArticleHeader } from "./layout"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  > section > textarea {
    height: 200px;
    margin: 10px 5px 10px 0px;
    border-radius: 4px;
    border: 1px solid white;
  }
  > button {
    width: 90px;
    color: #333;
    background-color: #fff;
    font-family: "MomsTypewriter", "Open Sans", "Helvetica Neue", Helvetica,
      Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 10px 6px 10px;
    transition-property: background-color, box-shadow;
    transition: all 0.5s ease;
    :hover {
      background-color: #6bf1d8;
      border: 1px solid #888;
      border-radius: 4px;
      border: 1px solid #ccc;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    }
  }
`
const Input = styled.input`
  width: 300px;
  height: 26px;
  border-radius: 4px;
  border: 1px solid white;
  margin: 5px;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`
const Name = styled(Input)`
  @media screen and (min-width: 960px) {
    margin-left: 16px;
  }
`
const Message = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
`
const TopFields = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const useForm = callback => {
  const [values, setValues] = useState({})
  const handleSubmit = async event => {
    if (event) {
      event.preventDefault()
    }
    callback()
  }
  const handleChange = event => {
    event.persist()
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }
  return {
    handleChange,
    handleSubmit,
    values,
  }
}

const ContactForm = () => {
  const { values, handleChange, handleSubmit } = useForm(sendEmail)
  const [emailState, setEmailState] = useState({
    messageSent: false,
    error: false,
  })
  const [sendClicked, setSendClicked] = useState(false)
  function sendEmail() {
    setSendClicked(true)
    // sleep(2000).then(setSendClicked(false))
    fetch("/.netlify/functions/send-mail", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(values),
    })
      .then(response => {
        if (response.ok) {
          setEmailState({ messageSent: true, error: false })
        } else {
          setSendClicked(false)
          setEmailState({ messageSent: false, error: true })
        }
      })
      .catch(err => {
        setSendClicked(false)
        setEmailState({ messageSent: false, error: true })
        console.error(`Email not sent!`, err)
      })
  }
  const minDate = new Date().toISOString().slice(0, -14)
  return (
    <Article>
      <ArticleHeader title="Contact Me" />
      {emailState.messageSent ? (
        <p>Thanks for contacting me. I'll be in touch!</p>
      ) : (
        <Form method="post" onSubmit={handleSubmit}>
          {emailState.error ? (
            <p style={{ color: "red" }}>
              Sorry, something went wrong! Try again?
            </p>
          ) : null}
          <TopFields>
            <label htmlFor="name">
              Name:
              <Name
                type="text"
                name="name"
                onChange={handleChange}
                value={values.name}
                required
              />
            </label>
            <label htmlFor="email">
              Email:
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                value={values.email}
                required
              />
            </label>
            <label htmlFor="deadline">
              DeadLine:
              <Input
                type="date"
                name="deadline"
                min={minDate}
                onChange={handleChange}
                value={values.deadline}
              />
            </label>
          </TopFields>
          <Message>
            <label htmlFor="message" style={{ marginTop: "15px" }}>
              Message
            </label>
            <textarea
              name="message"
              onChange={handleChange}
              required
              value={values.message}
            />
          </Message>
          {sendClicked ? (
            <p>Sending...</p>
          ) : (
            <button type="submit">Send</button>
          )}
        </Form>
      )}
    </Article>
  )
}

export default ContactForm
