import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import SingleVid from "./single-vid"
import { useStaticQuery, graphql } from "gatsby"
import { Article, Section, ArticleHeader } from "./layout"

const Figure = styled.figure`
  flex-basis: 0;
  flex-grow: 1;
  border-radius: 2px;
  box-shadow: 5px 5px 5px #888888;
  margin: 1em;
  > figcaption {
    display: block;
    margin: 0.2em 0 0;
    font-style: italic;
    font-size: 1.3em;
  }
`
const Desc = styled.p`
  flex-basis: 0;
  flex-grow: 2;
  font-size: max(2vw, 1.5em);
`

const AboutSection = styled(Section)`
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`
const About = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { fields: { slug: { eq: "/about/" } } }) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              content
              attribution
              vidId
              vidTitle
              vidDesc
            }
          }
        }
      }
    }
  `)
  const aboutInfo = data.allMarkdownRemark.edges[0].node.frontmatter
  const img = aboutInfo.image.childImageSharp.fluid
  return (
    <>
      <SingleVid
        id={aboutInfo.vidId}
        desc={aboutInfo.vidDesc}
        title={aboutInfo.vidTitle}
      />
      <Article>
        <ArticleHeader title={aboutInfo.title} />
        <AboutSection>
          <Figure key={aboutInfo.attribution}>
            <Img fluid={img} alt={aboutInfo.attribution} />
            <figcaption>{aboutInfo.attribution}</figcaption>
          </Figure>
          <Desc>{aboutInfo.content}</Desc>
        </AboutSection>
      </Article>
    </>
  )
}

export default About
