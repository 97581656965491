import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"
import About from "../components/about"

const Contact = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Contact Me" />
      <ContactForm />
      <About />
    </Layout>
  )
}

export default Contact
