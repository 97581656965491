import React from "react"
import styled from "@emotion/styled"
import { Article, Section, ArticleHeader } from "./layout"
import Video from "./video"

const VidSection = styled(Section)`
  flex-direction: column;
  > .videoFrame {
    width: 100%;
  }
`

const SingleVid = ({ id, title, desc }) => {
  return (
    <Article>
      <ArticleHeader title={title} />
      <VidSection>
        <Video videoSrcURL={id} videoTitle={title} />
        {desc ? <p dangerouslySetInnerHTML={{ __html: desc }}></p> : null}
      </VidSection>
    </Article>
  )
}

export default SingleVid
